import loadable from "@loadable/component";
import { inject } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { Store } from "@inferno/renderer-shared-core";
import type { AptivadaAppFragment, BlockFragment } from "@ihr-radioedit/inferno-webapi";
import { slugify } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { Card, Container, Grid } from "../../ui";
const log = ILog.logger("Contests.component");
import "./Contests.style.scss";

const ContentTile = loadable(() => import("../../components/tiles/ContentTile.component"));

interface ContestProps {
  block: BlockFragment;
  contests: AptivadaAppFragment[];
  store?: Store;
}

export const Contests = inject("store")(({ block, contests, store }: ContestProps) => {
  log.debug("", { contests, length: contests.length });
  if (!store) {
    return null;
  }

  const { site } = store;
  useEffect(() => store.storeBlock(block));

  const { t } = useTranslation();
  return (
    <Container className="component-contests">
      {!contests.length ? (
        <div>{t("no_promotions")}</div>
      ) : (
        <Grid columns={3}>
          {contests.map((contest: AptivadaAppFragment, i) => {
            const slug = `${slugify(contest.title.trim(), 6)}-${contest.parentAppId || contest.appId}`;
            const contestUrl = reverseRoute(site, "promotion", { slug });
            return (
              <Card key={i}>
                <ContentTile
                  title={contest.title}
                  url={contest.gridRedirect || contestUrl || ""}
                  slug={`contest_${i}`}
                  thumb={contest.displayImage || ""}
                />
              </Card>
            );
          })}
        </Grid>
      )}
    </Container>
  );
});
