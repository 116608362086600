import { inject } from "mobx-react";

import { ILog } from "@ihr-radioedit/inferno-core";
import { getContests } from "@ihr-radioedit/inferno-core";
import type { PageBlockInterface } from "../Block.component";
import { Contests } from "./Contests.component";
import { Remote } from "../../components/remote/Remote.component";
import * as Webapi from "@ihr-radioedit/inferno-webapi";
import type { Store } from "@inferno/renderer-shared-core";

const log = ILog.logger("ContestsTileLoader");

interface ContestsLoaderProps extends PageBlockInterface {
  store?: Store;
}

export const ContestsLoader = inject("store")(({ isPrimary, block, store }: ContestsLoaderProps) => {
  if (!store || store.site.sections.partners?.aptivada_switch !== true) {
    log.debug("Aptivada is disabled on the partners tab for this site");
    return null;
  }

  const { site, tags, getSdkOpts } = store;
  const { slug: accountId } = site.index;
  const cacheKey = `contestsloader-${accountId}`;

  return (
    <Remote<Webapi.AptivadaAppFragment[] | null | undefined>
      cacheKey={cacheKey}
      loader={() => getContests({ accountId }, tags.surrogateKeys, getSdkOpts())}
      showLoading={isPrimary}
    >
      {({ data }) => (data ? <Contests contests={data} block={block} /> : null)}
    </Remote>
  );
});

export default ContestsLoader;
